
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "sentry": {
    "tracesSampleRate": 1,
    "replaysSampleRate": 1,
    "release": "24.8.0"
  },
  "cookieFirst": {
    "key": "36457fbc-dedb-4ca8-83be-e6b461e12c80"
  },
  "locations": [
    {
      "slug": "fra01",
      "endpoint": "https://fra01.latency.g-portal.com/ping"
    },
    {
      "slug": "lon01",
      "endpoint": "https://lon01.latency.g-portal.com/ping"
    },
    {
      "slug": "iad01",
      "endpoint": "https://iad01.latency.g-portal.com/ping"
    },
    {
      "slug": "lax01",
      "endpoint": "https://lax01.latency.g-portal.com/ping"
    },
    {
      "slug": "dfw02",
      "endpoint": "https://dfw02.latency.g-portal.com/ping"
    },
    {
      "slug": "jfk01",
      "endpoint": "https://jfk01.latency.g-portal.com/ping"
    },
    {
      "slug": "syd01",
      "endpoint": "https://syd01.latency.g-portal.com/ping"
    },
    {
      "slug": "sin01",
      "endpoint": "https://sin01.latency.g-portal.com/ping"
    },
    {
      "slug": "cgh01",
      "endpoint": "https://cgh01.latency.g-portal.com/ping"
    },
    {
      "slug": "hnd01",
      "endpoint": "https://hnd01.latency.g-portal.com/ping"
    },
    {
      "slug": "tpe01",
      "endpoint": "https://tpe01.latency.g-portal.com/ping"
    },
    {
      "slug": "bom01",
      "endpoint": "https://bom01.latency.g-portal.com/ping"
    }
  ],
  "supportedCurrencies": [
    "EUR",
    "USD",
    "JPY",
    "GBP"
  ],
  "nuxt": {
    "buildId": "486aeed2-b0c1-4427-9066-00959ec320a7"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
